import React, { useContext } from 'react'
import styled from '@emotion/styled'
import Progress from './Progress'
import { ProgressContext } from './interfaces'
import ProgressCtx from './context/Progress'

const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  max-width: 100%;
  flex-wrap: row;
  position: absolute;
  width: 98%;
  padding: 5px;
  padding-top: 7px;
  align-self: center;
  z-index: 99;
  filter: drop-shadow(0 1px 8px #222);
`

export default () => {
  const { numArray, currentId } = useContext<ProgressContext>(ProgressCtx)
  return (
    <Wrapper>
      {numArray.map(i => (
        <Progress
          key={i}
          width={1 / numArray.length}
          active={i === currentId ? 1 : i < currentId ? 2 : 0}
        />
      ))}
    </Wrapper>
  )
}
