import React, { useEffect } from 'react'
import { ReactInstaStoriesProps, GlobalCtx } from './interfaces'
import Container from './Container'
import GlobalContext from './context/Global'

const ReactInstaStories = function(props: ReactInstaStoriesProps) {
  // preload the images
  useEffect(() => {
    props.stories.forEach((s, i) => {
      let images = []
      const url =
        typeof s === 'object' && s.url && (s.type === 'image' || !s.type)
          ? s.url
          : typeof s === 'string'
          ? s
          : null
      if (url) {
        images[i] = new Image()
        images[i].src = url
      }
    })
  }, [props.stories])

  const context: GlobalCtx = {
    stories: props.stories,
    width: props.width,
    height: props.height,
  }
  return (
    <GlobalContext.Provider value={context}>
      <Container />
    </GlobalContext.Provider>
  )
}

export default ReactInstaStories
