import React, { useState, useEffect, useContext, useRef } from 'react'
import { keyframes } from '@emotion/core'
import styled from '@emotion/styled'
import { StoryProps, GlobalCtx } from './interfaces'
import GlobalContext from './context/Global'

const Wrapper = styled.div`
  display: flex;
  position: relative;
  overflow: hidden;
`

const LoaderWrapper = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.9);
  z-index: 9;
  display: flex;
  justify-content: center;
  align-items: center;
  color: #ccc;
`

const loaderAnimation = keyframes`
  0% {
		transform: scale(0);
	}
	100% {
		transform: scale(1);
		opacity: 0;
	}
`

const Loader = styled.div`
  width: 40px;
  height: 40px;
  margin: 100px auto;
  background-color: #fff;

  border-radius: 100%;
  animation: ${loaderAnimation} 1s infinite ease-in-out;
`

const Story = (props: StoryProps) => {
  const [loaded, setLoaded] = useState<boolean>(false)

  const { width, height } = useContext<GlobalCtx>(GlobalContext)

  useEffect(() => {
    setLoaded(false)
    props.action('pause', true)
    vid.current &&
      vid.current.addEventListener('waiting', () => {
        props.action('pause', true)
      })
    vid.current &&
      vid.current.addEventListener('playing', () => {
        props.action('play', true)
      })
  }, [props.story])

  let vid = useRef<HTMLVideoElement>(null)

  useEffect(() => {
    if (vid.current) {
      if (props.playState) {
        vid.current.pause()
      } else {
        vid.current
          .play()
          .then(() => setLoaded(true))
          .catch(e => console.log(e))
      }
    }
  }, [props.playState])

  const storyLoaded = () => {
    try {
      setLoaded(true)
      props.action('play', true)
    } catch (e) {
      console.log(e)
    }
  }

  const videoLoaded = () => {
    try {
      if (vid.current) {
        props.getVideoDuration(vid.current.duration)
        vid.current
          .play()
          .then(() => {
            storyLoaded()
          })
          .catch(e => {
            props.action('pause')
            console.log(e)
          })
      }
    } catch (e) {
      console.log(e)
    }
  }

  const getStoryContent = () => {
    let source = props.story.url
    let storyContentStyles = {
      width: 'auto',
      maxWidth: '100%',
      maxHeight: '100%',
      margin: 'auto',
    }
    let type = props.story.type === 'video' ? 'video' : 'photo'
    return type === 'photo' ? (
      <img
        style={storyContentStyles}
        src={source}
        onLoad={storyLoaded}
        alt="Story"
      />
    ) : type === 'video' ? (
      <video
        ref={vid}
        style={storyContentStyles}
        src={source}
        controls={false}
        onLoadedData={videoLoaded}
        autoPlay
        playsInline
      />
    ) : null
  }

  return (
    <Wrapper style={{ width, height }}>
      {getStoryContent()}
      {!loaded && (
        <LoaderWrapper style={{ width, height }}>
          <Loader />
        </LoaderWrapper>
      )}
    </Wrapper>
  )
}

export default Story
