import React from 'react'
import styled from '@emotion/styled'
import { ProgressWrapperProps } from './interfaces'

const Wrapper = styled.div`
  height: 2px;
  max-width: 100%;
  background: #555;
  margin: 2px;
  border-radius: 2px;
  transition: opacity 400ms ease-in-out;
`

const ProgressWrapper = (props: ProgressWrapperProps) => (
  <Wrapper style={getProgressWrapperStyle(props)}>{props.children}</Wrapper>
)

const getProgressWrapperStyle = ({ width, pause, bufferAction }) => ({
  width: `${width * 100}%`,
  opacity: pause && !bufferAction ? 0 : 1,
})

export default ProgressWrapper
