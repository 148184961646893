import React, { useContext, useState, useRef, useEffect } from 'react'
import styled from '@emotion/styled'
import GlobalContext from './context/Global'
import ProgressContext from './context/Progress'
import Story from './Story'
import ProgressArray from './ProgressArray'
import { GlobalCtx } from './interfaces'

const defaultInterval = 4000

const Overlay = styled.div`
  position: absolute;
  height: inherit;
  width: inherit;
  display: flex;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  background: #111;
  position: relative;
`

export default function() {
  const [currentId, setCurrentId] = useState<number>(0)
  const [pause, setPause] = useState<boolean>(true)
  const [count, setCount] = useState<number>(0)
  const [bufferAction, setBufferAction] = useState<boolean>(true)
  const [videoDuration, setVideoDuration] = useState<number>(0)

  const mousedownId = useRef<number>()
  const animationFrameId = useRef<number>()

  const { width, height, stories } = useContext<GlobalCtx>(GlobalContext)

  useEffect(() => {
    if (!pause) {
      animationFrameId.current = requestAnimationFrame(incrementCount)
    }
    return () => {
      cancelAnimationFrame(animationFrameId.current)
    }
  }, [currentId, pause])

  useEffect(() => {
    setCount(0)
  }, [currentId, stories])

  const incrementCount = () => {
    setCount((count: number) => {
      const interval = getCurrentInterval()
      if (count < 100) {
        animationFrameId.current = requestAnimationFrame(incrementCount)
      } else {
        next()
      }
      return count + 100 / ((interval / 1000) * 60)
    })
  }

  const getCurrentInterval = () => {
    if (stories[currentId].type === 'video') return videoDuration
    return defaultInterval
  }

  const toggleState = (action: string, bufferAction?: boolean) => {
    setPause(action === 'pause')
    setBufferAction(!!bufferAction)
  }

  const previous = () => {
    if (currentId > 0) {
      setCurrentId(currentId - 1)
      setCount(0)
    }
  }

  const next = () => {
    setCurrentId((currentId + 1) % stories.length)
    setCount(0)
  }

  const debouncePause = (e: React.MouseEvent | React.TouchEvent) => {
    e.preventDefault()
    mousedownId.current = setTimeout(() => {
      toggleState('pause')
    }, 200)
  }

  const mouseUp = (e: React.MouseEvent | React.TouchEvent, type: string) => {
    e.preventDefault()
    mousedownId.current && clearTimeout(mousedownId.current)
    if (pause) {
      toggleState('play')
    } else {
      type === 'next' ? next() : previous()
    }
  }

  const getVideoDuration = (duration: number) => {
    setVideoDuration(duration * 1000)
  }

  return (
    <Container style={{ width, height }}>
      <ProgressContext.Provider
        value={{
          bufferAction: bufferAction,
          videoDuration: videoDuration,
          numArray: stories.map((_, i) => i),
          currentStory: stories[currentId],
          currentId,
          count,
          pause,
        }}
      >
        <ProgressArray />
      </ProgressContext.Provider>
      <Story
        action={toggleState}
        playState={pause}
        story={stories[currentId]}
        getVideoDuration={getVideoDuration}
      />
      <Overlay>
        <div
          style={{ width: '50%', zIndex: 999 }}
          role="button"
          aria-hidden="true"
          onTouchStart={debouncePause}
          onTouchEnd={e => mouseUp(e, 'previous')}
          onMouseDown={debouncePause}
          onMouseUp={e => mouseUp(e, 'previous')}
        />
        <div
          style={{ width: '50%', zIndex: 999 }}
          role="button"
          aria-hidden="true"
          onTouchStart={debouncePause}
          onTouchEnd={e => mouseUp(e, 'next')}
          onMouseDown={debouncePause}
          onMouseUp={e => mouseUp(e, 'next')}
        />
      </Overlay>
    </Container>
  )
}
