import React, { useContext } from 'react'
import styled from '@emotion/styled'
import { ProgressProps, ProgressContext } from './interfaces'
import ProgressWrapper from './ProgressWrapper'
import ProgressCtx from './context/Progress'

const Inner = styled.div`
  background: #fff;
  height: 100%;
  max-width: 100%;
  border-radius: 2px;
  transform-origin: center left;
  backface-visibility: hidden;
  perspective: 1000;
`

export default (props: ProgressProps) => {
  const { bufferAction, count, pause } = useContext<ProgressContext>(
    ProgressCtx
  )

  const getProgressStyle = ({ active }) => {
    switch (active) {
      case 2:
        return { width: '100%' }
      case 1:
        return { transform: `scaleX(${count / 100})` }
      case 0:
        return { width: 0 }
      default:
        return { width: 0 }
    }
  }

  const { width, active } = props
  return (
    <ProgressWrapper width={width} pause={pause} bufferAction={bufferAction}>
      <Inner style={getProgressStyle({ active })} />
    </ProgressWrapper>
  )
}
