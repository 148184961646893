import React from 'react'
import { GlobalCtx } from '../interfaces'

const GlobalContext = React.createContext<GlobalCtx>({
  stories: [],
  width: 360,
  height: 640,
})

export default GlobalContext
