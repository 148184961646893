import React, { useEffect, useState } from 'react'
import styled from '@emotion/styled'
import querystring from 'querystring'

import Stories from '../components/stories'
import SEO from '../components/seo'
import { useWindowSize } from '../components/useWindowSize'

const Wrapper = styled.div`
  background: rgb(17, 17, 17);
  width: 100vw;
  height: 100vh;
  margin: 0;
  display: flex;
  align-items: center;
  justify-content: center;
`

const StoriesPage = ({ location }) => {
  const size = useWindowSize()
  const ratio = 1800 / 1200
  const width =
    size.height > size.width / ratio
      ? Math.min(size.width, 1800)
      : Math.min(size.height, 1200) * ratio
  const height =
    size.height > size.width / ratio
      ? Math.min(size.width, 1800) / ratio
      : Math.min(size.height, 1200)

  const [{ stories, loading, error }, setState] = useState({
    stories: [],
    loading: true,
    error: null,
  })

  useEffect(() => {
    const { order } = querystring.parse(location.search.replace(/^\?/, ''))
    ;(async () => {
      try {
        const res = await fetch(
          `${process.env.GATSBY_API_URL}/stories?${querystring.stringify({
            order,
          })}`
        )
        const json = await res.json()
        if (!res.ok) {
          throw new Error(json.message || json)
        }

        setState({ stories: json.stories, loading: false, error: null })
      } catch (err) {
        console.error(err)
        setState({ stories: [], loading: false, error: err.message })
      }
    })()
  }, [location.search])

  return (
    <Wrapper>
      <SEO />
      {loading ? (
        'Loading...'
      ) : (
        <Stories width={width} height={height} stories={stories} />
      )}
      {error ? <span>{error}</span> : ''}
    </Wrapper>
  )
}

export default StoriesPage
